/**
 * Renders alternative tags list
 *
 * @flow
 */
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Layout from '../layouts/DefaultLayout';
import Tags from '../components/Tags';
import { PageHeader } from '../styles';


type Props = {
  data: {
    tags: {
      edges: Array<{
        node: {
          path: string,
          name: string,
        }
      }>
    }
  }
}

const AlternativeTags = ({ data }: Props) => (
  <Layout>
    <PageHeader>
      <h1>Ethical Alternative Tags</h1>
    </PageHeader>
    <Tags tags={data.tags.edges.map(({ node }) => node)} />
  </Layout>
);

export default () => (
  <StaticQuery
    query={graphql`
      query {
        tags: allWordpressWpAlternativeTags(sort: {fields: count, order: DESC}, filter: {count: {gt: 0}}) {
          edges {
            node {
              name
              path
            }
          }
        }
      }
    `}
    render={(data) => <AlternativeTags data={data} />}
  />
);
